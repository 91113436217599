import { render, staticRenderFns } from "./QuestionnaireResultsV2.vue?vue&type=template&id=2167c095"
import script from "./QuestionnaireResultsV2.vue?vue&type=script&lang=js"
export * from "./QuestionnaireResultsV2.vue?vue&type=script&lang=js"
import style0 from "./QuestionnaireResultsV2.vue?vue&type=style&index=0&id=2167c095&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports