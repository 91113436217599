<template>
  <div>
    <PageContainer
      :title="$translations['suggested-specialists'].title"
      :action="actions.close"
      :show-left-button="true"
      :ellipsis="true"
      :back="actions.back"
      :submit="handlerSubmit"
    >
      <template v-slot:content>
        <!-- specialists -->
        <div class="page-segment padding-top" v-if="!restViewVisible">
          <div class="page-segment-content">
            <!-- specialist card -->
            <SpecialistProfileCard
              class="questionnaire-result-view"
              :specialist="specialists[selected]"
              @changed="handlerSpecialists"
            />
          </div>
        </div>

        <!-- rest view -->
        <div class="page-segment padding-top" v-else>
          <div class="page-segment-content">
            <QuestionnaireRestView
              class="questionnaire-result-view"
              :is-final="restViewIsFinal"
              :is-empty="specialists?.length === 0"
              @callback="callSegmentEvents"
            />
          </div>
        </div>

        <div class="questionnaire-result-actions">
          <Button
            :class="{
              'questionnaire-result-actions-item-hidden': selected === 0
            }"
            type="circle"
            :size="responsiveClass('circle-smallest', 'circle-big')"
            icon="icon-arrow-big-outline-left"
            color="white"
            @clicked="handlerLeftArrow"
          />
          <Button
            :class="{
              'questionnaire-result-actions-item-hidden':
                restViewIsFinal || !specialists.length
            }"
            type="circle"
            :size="responsiveClass('circle-smallest', 'circle-big')"
            icon="icon-arrow-big-outline-right"
            color="white"
            @clicked="handlerRightArrow"
          />
        </div>
      </template>
    </PageContainer>

    <QuestionnaireExitModal
      @close="showExitModal = false"
      v-if="showExitModal"
    />
  </div>
</template>

<script>
import { PageContainer, Button, ResponsiveMixin } from '@seliaco/red-panda'

import SpecialistProfileCard from '@/views/specialist/components/SpecialistProfileCard.vue'

import QuestionnaireRestView from '../components/QuestionnaireRestView.vue'
import QuestionnaireExitModal from '../modal/QuestionnaireExitModal.vue'
import NavigationHandlerMixin from '@/mixins/navigation-handler-mixin'
import {
  QuestionnaireEvent,
  MatchingEvent,
  ScheduleButton
} from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'QuestionnaireResultsV2',
  components: {
    SpecialistProfileCard,
    PageContainer,
    QuestionnaireExitModal,
    QuestionnaireRestView,
    Button
  },
  props: {
    specialistsResult: Array,
    debugResult: Object,
  },
  mixins: [NavigationHandlerMixin, ResponsiveMixin],
  data () {
    const lang = this.$store.getters.language.lang

    const actions = {
      back: {
        callback: () => {
          this.navigateBack()
        }
      },
      close: {
        icon: 'icon-close',
        callback: () => {
          QuestionnaireEvent.closeQuestionnaireResultModal({
            user: this.user,
            result: this.specialistsResult,
            origin: 'Specialista Card Experiment B'
          })
          this.showExitModal = true
        }
      }
    }

    const selectedFromRoute = parseInt(this.$route.query.selected) || 0

    return {
      position: 0,
      selected: selectedFromRoute,
      lang,
      showExitModal: false,
      restViewVisible: false,
      restViewIsFinal: false,
      actions,
      specialists: [...this.specialistsResult]
    }
  },
  created () {
    if (!this.$route.query.selected) {
      this.selected = 0
    }
    if (this.selected === 4) {
      this.restViewVisible = true
    }
    if (this.selected === this.specialists.length - 1) {
      this.restViewIsFinal = true
      this.restViewVisible = true
    }
    if (this.specialists.length === 0) {
      this.restViewVisible = true
    }
  },
  mounted () {
    this.handlerExitResult()
    this.emitInitSegmentEvent()
  },
  methods: {
    emitInitSegmentEvent () {
      const result = this.specialistsResult.map((result) => {
        return {
          score_analysis: result.score_analysis,
          score: result.score,
          score_d: result.score_d,
          score_o: result.score_o,
          specialist_id: result.id,
          specialist_name: result.name,
        }
      })
      const body = {
        result,
        debug: this.debugResult
      }
      MatchingEvent.matchingResultsViewed(body)
    },
    emitClickSegmentEvent (direction) {
      MatchingEvent.matchingResultsArrowClicked({ direction })
    },
    handlerLeftArrow () {
      if (this.restViewVisible || this.restViewIsFinal) {
        this.restViewVisible = false
        this.restViewIsFinal = false
        return
      }

      if (this.selected > 0) {
        this.selected--
      }
      this.scrollTop()
      this.emitClickSegmentEvent('left')
    },
    handlerRightArrow () {
      if (this.selected < this.specialists.length) {
        if (this.selected === 4 && !this.restViewVisible) {
          this.restViewVisible = true
          return
        }
        if (this.selected === this.specialists.length - 1) {
          this.restViewIsFinal = true
          this.restViewVisible = true
          return
        }

        this.selected++

        if (this.restViewVisible) {
          this.restViewVisible = false
          this.restViewIsFinal = false
          return
        }
        this.scrollTop()
      }
      this.emitClickSegmentEvent('right')
    },
    scrollTop () {
      window.scrollTo(0, 0)
    },
    callSegmentEvents ($event) {
      const type = $event.key
      switch (type) {
        case 'see-others': {
          const body = {
            result_quality: this.notFoundSpecialists ? 'Bad' : 'Good',
            num_specialists_listed: this.specialists.length
          }
          MatchingEvent.matchingResultsSeeOthers(body)

          this.$router.replace({
            name: 'Search'
          })
          break
        }

        case 'edit-questionnaire': {
          const body = {
            result_quality: this.notFoundSpecialists ? 'Bad' : 'Good',
            num_specialists_listed: this.specialists.length
          }
          MatchingEvent.matchingResultsEdit(body)

          this.navigateBack()
          break
        }

        case 'orientation': {
          const body = {
            origin: 'Specialista Card Experiment B'
          }
          MatchingEvent.orientationStartBooking(body)

          this.$router.push({
            name: 'RecommendedCounselling',
            query: {
              back: this.$route.fullPath,
              selected: this.selected
            }
          })
          break
        }

        case 'specialist': {
          const specialist = this.specialists[this.selected]
          MatchingEvent.matchingResultsSpecialistProfileViewed({
            specialist_id: specialist.id,
            index: this.selected,
            highlighted: specialist.is_featured
          })
          break
        }

        case 'schedule': {
          const specialist = this.specialists[this.selected]
          const body = {
            user: this.$store.getters['auth/user'],
            specialist_id: specialist.id,
            specialist_name: specialist.name,
            is_public: true,
            highlighted: specialist.is_featured,
            origin: 'Matching',
            component: 'QuestionnaireResultsV2'
          }

          ScheduleButton.onClick(body)
          break
        }
      }
    },
    handlerSpecialists ($event) {
      const index = this.specialists.findIndex(
        (specialist) => specialist.id === $event.id
      )

      this.specialists[index] = { ...$event }
    },
    /**
     * Navigation
     */
    navigateBack () {
      this.$router.replace({
        name: 'QuestionnaireContainer'
      })
    },
    /**
     * Listeners
     */
    handlerExitResult () {
      window.onpopstate = () => {
        history.pushState(null, document.title, location.href)
      }
    },
    /**
     * Mixins overrides
     */
    handleBackButton () {
      this.navigateBack()
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role',
      user: 'auth/user'
    }),
    handlerSubmit () {
      if (this.restViewVisible) {
        return {}
      }

      return {
        icon: 'icon-appointment-off',
        iconLeft: true,
        text: this.$translations['suggested-specialists'].profile.schedule,
        callback: () => {
          this.callSegmentEvents({
            key: 'schedule'
          })

          this.$router.push({
            name: 'Schedule',
            query: {
              specialistId: this.specialists[this.selected].id,
              selected: this.selected,
              back: this.$route.fullPath
            }
          })
        }
      }
    }
  },
  watch: {
    selected: {
      handler (value) {
        this.callSegmentEvents({
          key: 'specialist'
        })

        this.$router.replace({
          query: {
            ...this.$route.query,
            selected: value
          }
        })
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass">

.questionnaire-result-view
  z-index: 2
  position: relative

.questionnaire-result-actions
  display: flex
  justify-content: space-between
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  max-width: 672px
  width: 100%
  z-index: 1

  &-item-hidden
    visibility: hidden
    user-select: none

@media (max-width: 840px)
  .questionnaire-result-actions
    padding: 0 16px
    top: 350px
    z-index: 3
</style>
