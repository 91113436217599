<template>
  <div v-if="lottie || svg || png">
    <lottie-player
      :src="lottie"
      class="lottie"
      background="transparent"
      preserveAspectRatio="xMidYMid slice"
      autoplay
      loop
      mode="normal"
      @error="handlerError('lottie')"
      v-if="showLottie"
    ></lottie-player>
    <img :src="svg" alt="svg" @error="handlerError('svg')" v-if="showSVG" />
    <img :src="png" alt="png" @error="handlerError('png')" v-if="showPNG" />
  </div>
</template>

<script>
import ResponsiveMixin from '@/mixins/responsive.mixin'

export default {
  name: 'RenderImage',
  props: {
    lottie: String,
    svg: String,
    png: String
  },
  mixins: [ResponsiveMixin],
  data () {
    return {
      lottieError: false,
      svgError: false,
      pngError: false
    }
  },
  computed: {
    showLottie () {
      return this.lottie && !this.lottieError
    },
    showSVG () {
      if (this.showLottie) {
        return false
      }

      return this.svg && !this.svgError
    },
    showPNG () {
      if (this.showLottie || this.showSVG) {
        return false
      }

      return this.png && !this.pngError
    }
  },
  methods: {
    handlerError (type) {
      switch (type) {
        case 'lottie':
          this.lottieError = true
          break
        case 'svg':
          this.svgError = true
          break
        case 'png':
          this.pngError = true
          break
      }
    }
  },
  watch: {
    windowWidth: {
      handler (width) {
        if (width > 840) {
          setTimeout(() => {
            if (!document.getElementsByClassName('lottie') || !document.getElementsByClassName('lottie')[0].children[0]) return
            const animationContainer = document.getElementsByClassName('lottie')[0].children[0].children[0].shadowRoot.children[0].children[0]
            animationContainer.style.height = '100vh'
          }, 50)
        } else {
          setTimeout(() => {
            if (!document.getElementById('login-container-content') || !document.getElementsByClassName('lottie')[0].children[0]) return
            const contentHeight = (document.getElementById('login-container-content').scrollHeight - 24)
            const animationContainer = document.getElementsByClassName('lottie')[0].children[0].children[0].shadowRoot.children[0].children[0]
            animationContainer.style.height = `calc(100% - ${contentHeight}px)`
          }, 50)
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.lottie
  height: 100vh
</style>
