<template>
  <div class="questionnaire-rest-view section-container-white-no-action">
    <MultiChooseView
      :callback="($event) => $emit('callback', $event)"
      :options="info.options"
      :title="info.title"
      title-class="heading"
      :subtitle="info.subtitle"
      :image="info.image"
      :lang="$store.getters.language.lang"
    />
  </div>
</template>

<script>
import { PNG, MultiChooseView } from '@seliaco/red-panda'

export default {
  name: 'QuestionnaireRestView',
  components: {
    MultiChooseView
  },
  props: {
    isFinal: Boolean,
    isEmpty: Boolean
  },
  methods: {
    buildInfo (type) {
      const baseOptions = [
        { ...this.baseInfo.options[0] },
        {
          ...this.baseInfo.options[1],
          text: { es: 'Modificar mis respuestas', en: 'Modify my answers', pt: 'Modificar minhas respostas' }
        },
        { ...this.baseInfo.options[2] },
      ]

      const results = {
        FINAL: {
          image: PNG.SearchSpecialist,
          title: {
            es: '¡Has explorado todos nuestros especialistas recomendados!',
            en: 'You have explored all our recommended specialists!',
            pt: 'Você explorou todos os nossos especialistas recomendados!'
          },
          subtitle: {
            es: '¿Qué te gustaría hacer ahora?',
            en: 'What would you like to do now?',
            pt: 'O que você gostaria de fazer agora' +
              '?'
          },
        },
        EMPTY: {
            image: PNG.NoConsultants,
            title: {
                es: '¡Lo sentimos, no hemos encontrado especialistas que coincidan con tus preferencias!',
                en: 'Sorry, we haven\'t found specialists that match your preferences!',
                pt: 'Desculpe, não encontramos especialistas que correspondam às suas preferências!'
            },
            subtitle: {
                es: 'Aquí encontrarás algunas recomendaciones que pueden ayudarte:',
                en: 'Here you will find some recommendations that can help you:',
                pt: 'Aqui você encontrará algumas recomendações que podem ajudá-lo:'
            }
        }
      }

      return {
        ...this.baseInfo,
        ...results[type],
        options: [...baseOptions]
      };
    },
    finalInfo () {
      return this.buildInfo('FINAL');
    },
    emptyInfo () {
      return this.buildInfo('EMPTY');
    }
  },
  computed: {
    baseInfo () {
      return {
        image: PNG.MatchingAgent,
        title: {
          es: '¿Necesitas ayuda para elegir a tu especialista?',
          en: 'Do you need help choosing your specialist?',
          pt: 'Você precisa de ajuda para escolher seu especialista?'
        },
        subtitle: {
          es: 'Elige una opción o sigue explorando tus recomendaciones.',
          en: 'Choose an option or continue exploring your recommendations.',
          pt: 'Escolha uma opção ou continue explorando suas recomendações.'
        },
        options: [
          {
            key: 'orientation',
            text: {
              es: 'Agendar orientación',
              en: 'Schedule guidance',
              pt: 'Agendar orientação'
            },
            caption: {
              es: 'Habla con un orientador para recibir una recomendación personalizada.',
              en: 'Talk to a counselor to receive a personalized recommendation.',
              pt: 'Converse com um orientador para receber uma recomendação personalizada.'
            },
            image: PNG.MatchingAgentIllustration
          },
          {
            key: 'edit-questionnaire',
            text: {
              es: 'Editar cuestionario',
              en: 'Edit questionnaire',
              pt: 'Editar questionário'
            },
            caption: {
              es: 'Ajusta tus preferencias para obtener nuevas recomendaciones.',
              en: 'Adjust your preferences to get new recommendations.',
              pt: 'Ajuste suas preferências para obter novas recomendações.'
            },
            image: PNG.CheckListIllustration
          },
          {
            key: 'see-others',
            text: {
              es: 'Ver todos los especialistas',
              en: 'See all specialists',
              pt: 'Ver todos os especialistas'
            },
            caption: {
              es: 'Explora el directorio completo de especialistas.',
              en: 'Explore the complete directory of specialists.',
              pt: 'Explore o diretório completo de especialistas.'
            },
            image: PNG.SearchAll
          }
        ],
      };
    },
    info () {
      if (this.isFinal) {
        return this.finalInfo();
      }
      if (this.isEmpty) {
        return this.emptyInfo();
      }
      return { ...this.baseInfo };
    }
  }
}
</script>

<style lang="sass" scoped>
.questionnaire-rest-view
  margin: 80px 0px
  padding: 24px

  @media (min-width: 1040px)
    padding: 28px
    margin: 100px 0px
</style>
