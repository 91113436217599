<template>
  <div>
    <QuestionnaireResultsV1
      :specialistsResult="specialistsResult"
      :service="service"
      v-if="version === 1"
    />
    <QuestionnaireResultsV2
      :specialistsResult="specialistsResult"
      :debug-result="debugResult"
      v-else
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import QuestionnaireResultsV1 from './pages/QuestionnaireResultsV1.vue'
import QuestionnaireResultsV2 from './pages/QuestionnaireResultsV2.vue'

export default {
  name: 'QuestionnaireResults',
  components: {
    QuestionnaireResultsV1,
    QuestionnaireResultsV2
  },
  props: {
    specialistsResult: Array,
    service: String,
    debugResult: Object
  },
  computed: {
    ...mapGetters({
      version: 'growthBook/matchingResult'
    })
  }
}
</script>
