<template>
  <div>
    <PageContainer
      :title="$translations['suggested-specialists'].title"
      :action="actions.close"
      :show-left-button="true"
      :has-shy-header="true"
      :ellipsis="true"
      :back="actions.back"
      v-if="!loading"
    >
      <template v-slot:content>
        <div class="segment padding-top-zero">
          <div class="segment-content body" v-text="description" />
        </div>
        <!-- specialists -->
        <div class="segment padding-top">
          <div class="segment-content">
            <!-- specialist card -->
            <SpecialistCard
              v-for="(specialist, key) in specialists"
              :key="key"
              :specialist="specialist"
              :content-version="2"
            />
          </div>
        </div>

        <!-- go back -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div class="white-section">
              <div
                class="body"
                v-text="
                  $translations['suggested-specialists']['see-questionnaire']
                "
              />
              <Button
                type="link"
                :text="
                  $translations['suggested-specialists']['other-specialists']
                "
                @clicked="seeOthersSpecialists"
              />

              <Button
                type="link"
                :text="
                  $translations['suggested-specialists']['go-questionnaire']
                "
                @clicked="navigateQuestionnaire"
              />
            </div>
          </div>
        </div>

        <!-- FAQs -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div
              class="small-tag"
              v-text="$translations['appointment-summary'].faq"
            />

            <Accordion
              v-for="(question, key) in faq"
              :key="key"
              :text="question.title[lang]"
              :hasCustomContent="true"
              :is-collapsed="currentFaqIndex !== key"
              :custom-controller="true"
              @collapse="changeCurrentFaqIndex($event, key)"
            >
              <template v-slot:accordion>
                <div v-html="question.description[lang]"></div>
              </template>
            </Accordion>
          </div>
        </div>
      </template>
    </PageContainer>

    <QuestionnaireExitModal
      @close="showExitModal = false"
      v-if="showExitModal"
    />
  </div>
</template>

<script>
import {
  Settings,
  Accordion,
  Button,
  PageContainer,
  RoleType
} from '@seliaco/red-panda'

import SpecialistCard from '@/components/cards/specialist-card/SpecialistCardWrapper.vue'
import QuestionnaireExitModal from '../modal/QuestionnaireExitModal.vue'
import { QuestionnaireEvent, MatchingEvent } from '@/types/events'
import NavigationHandlerMixin from '@/mixins/navigation-handler-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'QuestionnaireResultsV1',
  components: {
    SpecialistCard,
    Accordion,
    PageContainer,
    Button,
    QuestionnaireExitModal
  },
  props: {
    specialistsResult: Array,
    service: String
  },
  mixins: [NavigationHandlerMixin],
  data () {
    const lang = this.$store.getters.language.lang

    return {
      position: 0,
      lang,
      loading: true,
      faq: [],
      showExitModal: false,
      actions: {
        back: {
          callback: () => {
            this.navigateBack()
          }
        },
        close: {
          icon: 'icon-close',
          callback: () => {
            QuestionnaireEvent.closeQuestionnaireResultModal({
              user: this.user,
              result: this.specialistsResult.map((o) => {
                return {
                  id: o.id,
                  score: o.score,
                  score_analysis: o.score_analysis,
                  score_d: o.score_d,
                  score_o: o.score_o
                }
              })
            })
            localStorage.removeItem('suggestedSpecialists')
            localStorage.removeItem('suggestedSpecialistsPosition')
            this.showExitModal = true
          }
        }
      },
      currentFaqIndex: null,
      loaderTimeout: null
    }
  },
  created () {
    this.getFAQ()
  },
  mounted () {
    this.handlerExitResult()
  },
  methods: {
    getFAQ () {
      this.loading = true
      Settings.read({
        columns: 'value',
        eq: {
          key: 'FAQ_QUESTIONNAIRE'
        }
      })
        .then((response) => {
          if (response.data[0].value) {
            this.faq = JSON.parse(response.data[0].value)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    seeOthersSpecialists () {
      this.loaderTimeout = Date.now()
      if (this.position >= Math.ceil(this.specialistsResult.length / 5) - 1) {
        this.$globalEvent.$emit('modal/dont-find-specialists', {
          showDialog: {
            dontFindSpecialists: { open: true, close: false }
          }
        })
      } else {
        this.loading = true
        this.position++
      }

      if (this.loading) {
        setTimeout(() => {
          this.loading = false
          window.scrollTo(0, 0)
        }, 1000)
      }
    },
    changeCurrentFaqIndex (value, key) {
      this.currentFaqIndex = value ? key : null
    },
    callSegmentEvents (type, modalType) {
      switch (type) {
        case 'see-others': {
          const body = {
            result_quality: this.notFoundSpecialists ? 'Bad' : 'Good',
            num_specialists_listed: this.specialistsResult.length,
            option: modalType
          }
          MatchingEvent.matchingResultsSeeOthers(body)
          break
        }

        case 'edit-questionnaire': {
          const body = {
            result_quality: this.notFoundSpecialists ? 'Bad' : 'Good',
            num_specialists_listed: this.specialistsResult.length
          }
          MatchingEvent.matchingResultsEdit(body)
          break
        }

        case 'orientation': {
          const body = {
            origin: 'Matching results'
          }
          MatchingEvent.orientationStartBooking(body)
          break
        }
      }
    },
    /**
     * Navigation
     */
    navigateBack () {
      if (this.position <= 0) {
        this.navigateQuestionnaire()
      }

      if (this.position > 0) {
        this.loading = true
        this.position--
      }

      if (this.loading) {
        setTimeout(() => {
          this.loading = false
          window.scrollTo(0, 0)
        }, 1000)
      }
    },
    navigateQuestionnaire () {
      this.$router.replace({
        name: 'QuestionnaireContainer'
      })
    },
    navigateRecommended () {
      this.callSegmentEvents('orientation')

      this.$router.push({
        name: 'RecommendedCounselling',
        query: {
          back: this.$route.fullPath
        }
      })
    },
    /**
     * Listeners
     */
    handlerExitResult () {
      window.onpopstate = () => {
        history.pushState(null, document.title, location.href)
      }
    },
    /**
     * Mixins overrides
     */
    handleBackButton () {
      this.navigateBack()
    }
  },
  computed: {
    description () {
      return this.notFoundSpecialists
        ? this.$translations['suggested-specialists']['not-description']
        : this.$translations['suggested-specialists'].description
    },
    showOrientationFlow () {
      const serviceAllow = ['Psychiatrist', 'Psychologist']

      if (this.role === RoleType.USER_HIDE_PRICES) {
        return false
      }

      return serviceAllow.includes(this.service)
    },
    specialists () {
      const init = this.position * 5
      return this.specialistsResult.slice(init, init + 5)
    },
    ...mapGetters({
      role: 'auth/role',
      user: 'auth/user'
    })
  },
  watch: {
    '$route.query': {
      handler (val) {
        if (val.position) {
          this.position = Number(val.position)
        }
      },
      immediate: true,
      deep: true
    },
    position: {
      handler (val) {
        if (val) {
          this.$router.replace({
            query: {
              ...this.$route.query,
              position: val
            }
          })
        }
      },
      immediate: true
    },
    loading: {
      handler (val) {
        if (val) {
          this.$loader.handleShowLoader({
            lang: this.$store.getters.language.lang,
            type: 'degrade',
            key: 'MATCHING'
          })
          this.loaderTimeout = Date.now()
        } else {
          const timeElapsed = Date.now() - this.loaderTimeout
          const delay = Math.max(0, 3000 - timeElapsed)

          setTimeout(() => {
            this.$loader.handleRemoveLoader()
          }, delay)
        }
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="sass">
.content
  text-align: center
  font-size: var(--lg)
  color: var(--gray-80)

.white-section
  padding: 16px 16px 0
  text-align: center

.buttons-container
  display: flex
  flex-direction: column
  gap: 16px
  margin-top: 16px
</style>
