<template>
  <div>
    <DialogContainer
      v-if="!showHelpModal"
      icon="icon-question-mark-on text-purple"
      contentIconBackground="bg-purple-10"
      @closeDialog="$emit('close')"
      :title="config && config.title[lang]"
    >
      <template v-slot:content>
        <div v-if="config && !loading">
          <div
            class="body margin-bottom"
            v-text="config && config.subtitle[lang]"
          />
          <div class="fields-container">
            <DynamicForm
              @form="setValue($event)"
              :fields="config.fields"
              :lang="lang"
            />

            <InputTextField
              v-if="showInput"
              v-model="form.additional_data"
              ref-name="dont_like_specialists_survey_text"
              :label="$translations['suggested-specialists'].dialog.label"
              :lang="lang"
              class="input-field"
              @change="handlerAdditionalData"
            />
          </div>

          <Button
            @clicked="submit"
            :text="$translations.buttons.send"
            :disable="!isValid"
          />
        </div>
        <div v-if="loading" class="skeleton-container">
          <Skeleton
            class="margin-top margin-bottom-xs"
            width="100%"
            height="62px"
            border-radius="8px"
          />
          <Skeleton
            v-for="index in 6"
            :key="index"
            width="100%"
            height="60px"
            border-radius="8px"
          />
          <Skeleton
            class="margin-top"
            width="100%"
            height="48px"
            border-radius="8px"
          />
        </div>
      </template>
    </DialogContainer>
    <QuestionnaireHelpModal
      v-if="showHelpModal"
      @close-modal="showHelpModal = false"
    />
  </div>
</template>

<script>
import {
  Button,
  DialogContainer,
  Settings,
  DynamicForm,
  Skeleton
} from '@seliaco/red-panda'
import QuestionnaireHelpModal from './QuestionnaireHelpModal.vue'
import { QuestionnaireEvent } from '@/types/events'

import { InputTextField } from '@seliaco/sea-otter'
import { mapGetters } from 'vuex'

export default {
  name: 'QuestionnaireExitModal',
  components: {
    DialogContainer,
    Button,
    DynamicForm,
    Skeleton,
    InputTextField,
    QuestionnaireHelpModal
  },
  data () {
    const form = {
      help: '',
      additional_data: null
    }

    return {
      loading: false,
      config: null,
      form,
      showInput: false,
      showHelpModal: false,
      lang: '',
      fields: [],
      isValid: false
    }
  },
  created () {
    this.lang = this.$store.getters.language.lang
    this.getConfig()
  },
  methods: {
    async getConfig () {
      this.loading = true

      Settings.get('MATCHING_EXIT_FORM')
        .then((setting) => {
          this.config = setting.parse_value
          this.fields = this.config.fields[0].options.map((item) => {
            item.name = item.name[this.lang]
          })
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit () {
      QuestionnaireEvent.closeQuestionnaireResult({
        user: this.user,
        result: this.form
      })

      if (this.form.help === 'EXPLORER') {
        return this.handlerNavigation()
      }

      this.showHelpModal = true
    },
    async handlerNavigation () {
      this.$router.push({
        name: 'Home'
      })
    },
    setValue (event) {
      this.isValid = event.isValid
      if (event.isValid && event?.values) {
        this.showInput = event?.values.help?.values.value === 'OTHER'
        this.form.help = event?.values.help?.values.value

        if (this.showInput) {
          this.isValid = Boolean(this.form.additional_data)
        }
      }
    },
    handlerAdditionalData (event) {
      this.isValid = Boolean(event)
      this.form.additional_data = event
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  }
}
</script>

<style lang="sass" scoped>
.skeleton-container
  display: flex
  flex-direction: column
  align-items: center
  gap: 8px
.fields-container
  text-align: initial
  margin-bottom: 24px
  max-height: 290px
  overflow: auto
  .input-field
    margin: 8px 0 24px
  ::v-deep .select-opened
    margin-bottom: 0

@media (min-height: 668px)
  .fields-container
    max-height: none
</style>
