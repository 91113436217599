<template>
  <div
    class="specialist-profile-card section-container-white-no-action margin-bottom-sm"
  >
    <InfoBanner
      v-if="dSpecialist?.is_featured"
      text-color="gray-60"
      background="white"
      border="1px solid"
      border-color="purple-50"
      padding="8px 12px"
      margin="0 0 16px"
    >
      <template v-slot>
        <div class="banner">
          <img class="sparkles-img" :src="bannerImg" alt="sparkles" />
          <HtmlViewer :html-content="dSpecialist?.is_featured_text" />
        </div>
      </template>
    </InfoBanner>

    <SpecialistPicCard
      :specialist="dSpecialist"
      @fetched-favorite="handleSpecialistFavoriteFetched"
      @changed-favorite="handleSpecialistFavoriteChanged"
    />

    <!-- experience and satisfaction -->
    <div class="metrics">
      <!-- experience -->
      <div class="body-small" v-html="experienceText" />

      <!-- satisfaction -->
      <div class="body-small metrics-satisfaction">
        {{ $translations['suggested-specialists'].profile.satisfaction }}
        <div class="icon-star-on text-yellow"></div>
        <b v-text="dSpecialist.rating" />
      </div>
    </div>

    <!-- specialities -->
    <div
      v-if="dSpecialist?.specialities?.length"
      class="section-container-gray info-content"
    >
      <span
        class="body-small info-content-text"
        v-html="specialitiesDescription"
      />
    </div>

    <div
      class="body"
      v-if="dSpecialist.consultation_style_description"
      v-text="dSpecialist.consultation_style_description"
    />

    <!-- availability -->
    <div
      class="page-segment-short section"
      v-if="dSpecialist.availability.date"
    >
      <div class="availability">
        <Chip
          v-if="dSpecialist.availability.date"
          color="purple"
          icon-position="left"
          icon="icon-bolt-off"
          size="small"
          :show-icon="true"
          :text="dSpecialist.availability.date"
        />

        <div class="availability-items">
          <div
            class="availability-items-text body-small text-gray-80"
            v-text="availabilityItem()"
          />
        </div>
      </div>
    </div>

    <!-- badges -->
    <div class="badges" v-if="dSpecialist.experience_badge">
      <div
        class="badges-retention margin-bottom-xs"
        v-if="dSpecialist.has_retention_badge"
      >
        <img class="badges-retention-img" :src="img" alt="badge" />
        <div
          class="body text-purple-110"
          v-text="
            $translations['suggested-specialists'].profile[
              'highlight-specialist'
            ]
          "
        />
        <div
          class="icon-question-mark-off icon-display text-gray-40"
          v-tooltip="$translations['suggested-specialists'].profile.badge"
        />
      </div>
      <div class="badges-experience text-purple-110 body">
        <b>+{{ dSpecialist.experience_badge }}</b>
        {{
          $translations['suggested-specialists'].profile['count-appointments']
        }}
      </div>
    </div>

    <!-- reviews -->
    <div class="page-segment-short section" v-if="dSpecialist?.reviews?.length">
      <div class="page-segment-short-content">
        <div class="section-header margin-bottom">
          <div
            class="section-header-text heading-small"
            v-text="$translations['suggested-specialists'].profile.feedback"
          />
        </div>

        <!-- review item -->
        <div class="section-container-white padding-y review-item">
          <!-- date -->
          <div class="date margin-bottom-sm">
            <div class="icon icon-recommend-on" />
            <div class="text content-title" v-text="handlerDateReview" />
          </div>
          <!-- content -->
          <SectionClamp
            :text="dSpecialist.reviews[reviewSelected].content"
            :maxLines="3"
            text-color="gray-80"
          />
        </div>

        <div class="review-actions">
          <Button
            :text="$translations['suggested-specialists'].profile.previous"
            type="link"
            icon="icon-arrow-small-outline-left"
            :iconLeft="true"
            @clicked="handlerReviews"
          />
          <Button
            :text="$translations['suggested-specialists'].profile.next"
            type="link"
            icon="icon-arrow-small-outline-right"
            @clicked="handlerReviews(true)"
          />
        </div>
      </div>
    </div>

    <!-- bio -->
    <div class="page-segment-short section" v-if="dSpecialist?.bio">
      <div class="page-segment-short-content">
        <div class="section-header margin-bottom">
          <div class="icon-about-me icon-display" />
          <div
            class="section-header-text heading-small"
            v-text="$translations['suggested-specialists'].profile['about-me']"
          />
        </div>
        <SectionClamp :text="dSpecialist.bio" text-color="gray-80" />
      </div>
    </div>

    <!-- First Session -->
    <div
      class="page-segment-short section"
      v-if="dSpecialist?.first_session_description"
    >
      <div class="page-segment-short-content">
        <div class="section-header margin-bottom">
          <div class="icon-display icon-activity" />
          <div
            class="section-header-text heading-small"
            v-text="
              $translations['suggested-specialists'].profile['first-session']
            "
          />
        </div>
        <SectionClamp
          :text="dSpecialist.first_session_description"
          text-color="gray-80"
        />
      </div>
    </div>

    <!-- Consultation Style -->
    <div
      class="page-segment-short section"
      v-if="dSpecialist?.consultation_style_description"
    >
      <div class="page-segment-short-content">
        <div class="section-header margin-bottom">
          <div class="icon-display icon-therapeutic-approach" />
          <div
            class="section-header-text heading-small"
            v-text="
              $translations['suggested-specialists'].profile['therapy-style']
            "
          />
        </div>
        <div class="chips" v-if="dSpecialist?.consultation_style.length">
          <Chip
            v-for="(item, index) in dSpecialist.consultation_style"
            :key="index"
            size="pill-field"
            color="white-outline"
            icon-position="left"
            :emoji="item.icon_code"
            :text="item.name"
          />
        </div>

        <SectionClamp
          class="margin-top"
          :text="dSpecialist.consultation_style_description"
          text-color="gray-80"
        />
      </div>
    </div>

    <!-- Specialities -->
    <div
      class="page-segment-short section"
      v-if="dSpecialist?.specialities?.length"
    >
      <div class="page-segment-short-content">
        <div class="section-header">
          <div class="icon-display icon-specialities" />
          <div
            class="section-header-text heading-small"
            v-text="$translations['suggested-specialists'].profile.specialities"
          />
        </div>
        <SectionClamp
          :items="dSpecialist.specialities"
          :maxItems="4"
          text-color="gray-80"
        />
      </div>
    </div>

    <!-- Population -->
    <div
      class="page-segment-short section"
      v-if="dSpecialist?.populations?.length"
    >
      <div class="page-segment-short-content">
        <div
          class="content-title"
          v-text="$translations['suggested-specialists'].profile['work-with']"
        />
        <div class="population-chips">
          <Chip
            v-for="(item, index) in dSpecialist.populations"
            :key="index"
            size="small"
            color="purple"
            :text="item.name"
          />
        </div>
      </div>
    </div>

    <!-- Experiences -->
    <div
      class="page-segment-short section"
      v-if="dSpecialist?.experiences?.length"
    >
      <div class="page-segment-short-content">
        <div class="section-header">
          <div class="icon-display icon-certificate" />
          <div
            class="section-header-text heading-small"
            v-text="
              $translations['suggested-specialists'].profile.certifications
            "
          />
        </div>
        <SectionClamp
          :items="dSpecialist.experiences"
          :extended-item="true"
          :maxItems="2"
          text-color="gray-80"
        />
      </div>
    </div>

    <!-- Address -->
    <div
      class="page-segment-short"
      v-if="dSpecialist.in_person && dSpecialist.address_full"
    >
      <div class="page-segment-short-content section">
        <div class="section-header-between margin-bottom">
          <div
            class="heading-small section-header-text"
            v-text="$translations['suggested-specialists'].profile.address"
          />
          <OpenInGoogleMaps
            v-if="dSpecialist.address_place_id"
            class="header-link"
            :language="$store.getters.language.lang"
            :place_id="dSpecialist.address_place_id"
            :full-address="dSpecialist.address_full"
          />
        </div>

        <div class="address-container">
          <span
            class="icon-display icon-consultory-directory text-gray-60 margin-right-sm"
          />
          <span class="body-small" v-text="dSpecialist.address_full" />
        </div>
      </div>
    </div>

    <div
      class="page-segment-short section"
      v-if="dSpecialist.services.length > 0"
    >
      <div class="page-segment-short-content">
        <!-- Header -->
        <div class="section-header">
          <div class="icon-prices-offered icon-display" />
          <div
            class="section-header-text heading-small"
            v-text="$translations['suggested-specialists'].profile.services"
          />
        </div>

        <!-- Modalities -->
        <ModalitySelector
          @changed="setModality"
          :presential-option="handlerInPersonService"
        />

        <!-- Services -->
        <ServiceCard
          v-for="(service, index) in services"
          :key="index"
          :service="service"
          :version="2"
          class="margin-bottom"
        />

        <!-- Packages -->
        <PackagesBanner
          v-if="dSpecialist.packages && dSpecialist.packages.description"
          :package-data="dSpecialist.packages"
          :specialist="dSpecialist"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Chip,
  RoleMixin,
  OpenInGoogleMaps,
  Button,
  SpecialistProfile,
  tooltip,
  InfoBanner,
  HtmlViewer
} from '@seliaco/red-panda'
import SpecialistPicCard from '@/components/cards/specialist-card/SpecialistPicCard.vue'
import SectionClamp from '@/components/sections/SectionClamp.vue'
import ServiceCard from '@/views/specialist/components/ServiceCard'
import PackagesBanner from '@/views/specialist/components/PackagesBanner'
import ModalitySelector from '@/components/fields/ModalitySelector'

import ResponsiveMixin from '@/mixins/responsive.mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialistProfileCard',
  mixins: [ResponsiveMixin, RoleMixin],
  directives: { tooltip },
  components: {
    Chip,
    SpecialistPicCard,
    SectionClamp,
    ServiceCard,
    PackagesBanner,
    ModalitySelector,
    OpenInGoogleMaps,
    Button,
    InfoBanner,
    HtmlViewer
  },
  props: {
    specialist: Object
  },
  data () {
    return {
      bannerImg: require('@/assets/images/sparkles.png'),
      img: require('@/assets/badge.png'),
      modality: true,
      reviewSelected: 0,
      dSpecialist: null,
      specialitiesDescription: null
    }
  },
  methods: {
    async getServicesAndPackage () {
      const packagesDataPromise = async () => {
        if (this.dSpecialist.skipPackageFetch) {
          return
        }

        const packages = await SpecialistProfile.getPackageBanner(
          this.dSpecialist.id
        )

        this.dSpecialist = {
          ...this.dSpecialist,
          packages,
          skipPackageFetch: true
        }

        this.$emit('changed', this.dSpecialist)
      }

      const servicesDataPromise = async () => {
        if (this.dSpecialist.skipServiceFetch) {
          return
        }

        const servicesWithDiscounts = await SpecialistProfile.getServicesV3(
          this.dSpecialist.id
        )

        const services = this.dSpecialist.services.map((service) => {
          const serviceData = servicesWithDiscounts.find(
            (item) => item.slug === service.slug
          )

          return {
            ...serviceData,
            ...service,
            price: serviceData?.price || service.price,
            original_price: service.price
          }
        })

        this.dSpecialist = {
          ...this.dSpecialist,
          services,
          skipServiceFetch: true
        }

        this.$emit('changed', this.dSpecialist)
      }

      await Promise.all([packagesDataPromise(), servicesDataPromise()])
    },
    mapSpecialities () {
      if (!this.dSpecialist.specialities) {
        return
      }

      const specialitiesArray = [...this.dSpecialist.specialities]

      this.specialitiesDescription = `<span style='color: #9C73EB; font-weight: 600;'>${
        this.$translations['suggested-specialists'].profile.specialize
      }</span> ${specialitiesArray
        .sort((a, b) =>
          a.is_featured === b.is_featured ? 0 : a.is_featured ? -1 : 1
        )
        .map((item) => {
          if (item.is_featured) {
            return `<b>${item.name}</b>`
          }
          return item.name
        })
        .join(', ')}`
    },
    mapperService (services) {
      return services.map((item) => {
        return {
          icon: item.icon_name,
          title: item.name,
          description: item.description,
          duration: item.duration || null,
          price: item.price,
          original_price: item.original_price,
          max_package_discount: item?.max_package_discount || null,
          package: {
            max_uses: item?.package?.max_uses || null,
            uses_left: item?.package?.uses_left || null
          },
          virtual_enabled: item.virtual_enabled,
          in_person_enabled: item.in_person_enabled,
          chip: {
            color: item?.chip?.color || null,
            text: item?.chip?.text || null
          },
          slug: item.slug
        }
      })
    },
    availabilityItem () {
      let result = ''
      if (!this.dSpecialist?.availability?.slots?.length) return

      for (let i = 0; i < this.dSpecialist.availability.slots.length; i++) {
        let textslot = `${this.dSpecialist.availability.slots[i]}`
        if (i > 0) {
          textslot = `\xa0• ${this.dSpecialist.availability.slots[i]}`
        }
        result += textslot
      }
      return result
    },
    setModality (value) {
      this.modality = value.code === 'ONLINE'
    },
    handlerReviews (isNext = false) {
      if (isNext) {
        if (this.reviewSelected < this.dSpecialist.reviews.length - 1) {
          this.reviewSelected++
        } else {
          this.reviewSelected = 0
        }
      } else {
        if (this.reviewSelected > 0) {
          this.reviewSelected--
        } else {
          this.reviewSelected = this.dSpecialist.reviews.length - 1
        }
      }
    },
    handleSpecialistFavoriteFetched () {
      this.$emit('changed', this.dSpecialist)
    },
    handleSpecialistFavoriteChanged (favoriteId) {
      this.dSpecialist.favoriteId = favoriteId

      this.$emit('changed', this.dSpecialist)
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role',
      serviceCardVer: 'growthBook/getServiceCardVer'
    }),
    services () {
      if (!this.modality) {
        return this.mapperService(
          this.dSpecialist.services?.filter((item) => item.in_person_enabled)
        )
      }
      return this.mapperService(this.dSpecialist.services)
    },
    experienceText () {
      if (!this.dSpecialist.experience) return ''

      return this.$translations[
        'suggested-specialists'
      ].profile.experience.replace('{experience}', this.dSpecialist.experience)
    },
    handlerDateReview () {
      if (this.dSpecialist.reviews.length === 0) return ''

      const dateTime = this.$luxon.DateTime
      return dateTime
        .fromISO(this.dSpecialist.reviews[this.reviewSelected].date)
        .toFormat('LLL dd, yyyy')
    },
    handlerInPersonService () {
      return this.dSpecialist.services?.some(
        (item) => item.in_person_enabled === true
      )
    }
  },
  watch: {
    specialist: {
      handler (value) {
        this.dSpecialist = value
        this.getServicesAndPackage()
        this.reviewSelected = 0
        this.mapSpecialities()
        this.modality = true
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.specialist-profile-card
  .banner
    display: flex
    gap: 8px
    align-items: center
    .sparkles-img
      width: 25px
      height: 25px
  .page-segment-short
    border-radius: 0px
  .section
    padding: 16px 0
    &-header
      display: flex
      align-items: center
      gap: 8px
      min-height: 40px
      overflow: hidden
      &-between
        display: flex
        justify-content: space-between
        align-items: center
      .icon-display
        color: var(--purple)
      &-text
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap

    .chips
      display: flex
      gap: 4px
      flex-wrap: wrap
      align-items: flex-end
    .chip
      border-radius: var(--border-3)
      ::v-deep .body-small
        color: var(--gray-80) !important
        font-weight: 600
      ::v-deep .icon-purple
      color: var(--purple) !important

  .info-content
    display: grid
    margin-bottom: 16px
    &-text
      text-overflow: ellipsis
      overflow: hidden
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical

  .badges
    &-retention
      display: grid
      align-items: center
      gap: 6px
      grid-template-columns: min-content 1fr min-content
      &-img
        min-width: 24px
        height: 24px
    &-experience
      display: flex
      gap: 6px
      align-items: center
    &-experience ::v-deep b
      position: relative
      z-index: 1
      &::before
        content: ''
        background: var(--purple-10)
        border-radius: 999999px
        width: 24px
        height: 24px
        position: absolute
        left: 50%
        transform: translate(-50%)
        z-index: -1
  .review-item
    min-height: 127px
  .review-actions
    display: grid
    justify-content: end
    grid-template-columns: min-content min-content
    gap: 16px
    ::v-deep .btn
      padding: 0
      color: var(--gray-80)
  .date
    display: flex
    gap: 8px
    .icon
      flex: none
      color: var(--purple)
      font-size: var(--xl)
      align-self: center
    .text
      text-transform: capitalize
      flex-grow: 1
      align-self: center
      color: var(--gray-80)

  .metrics
    display: flex
    gap: 16px
    padding: 16px
    align-items: center
    &-satisfaction
      display: flex
      gap: 4px
      align-items: center

  .availability
    display: flex
    gap: 8px
    padding: 8px 0

    @media (min-width: 1040px)
      gap: 16px

    &-items
      display: flex
      align-items: center
      width: 100%
      overflow: hidden
      &-text
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

  .address-container
    display: flex

  .population-chips
    margin-top: 10px
    display: flex
    gap: 4px
    flex-wrap: wrap
    ::v-deep .body-small
      overflow: unset
      text-overflow: unset
</style>
