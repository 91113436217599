<template>
  <DialogContainer
    :title="config.title"
    illustration="matching_agent_Illustration"
    @closeDialog="$emit('close-modal')"
  >
    <template v-slot:content>
      <div class="body-big" v-html="config.description" />
      <Button
        class="margin-top"
        v-for="button in buttons"
        :key="button.key"
        :text="button.text"
        :type="button.type"
        :color="button.color"
        @clicked="hdlClickOptions(button.key)"
      />
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Button } from '@seliaco/red-panda'

export default {
  name: 'QuestionnaireHelpModal',
  components: {
    DialogContainer,
    Button
  },
  data () {
    const translation = this.$translations.questionnaire
    const config = {
      title: translation['matching-help'].title,
      description: translation['matching-help'].description
    }

    const buttons = [
      {
        key: 'orientation',
        text: this.$translations.buttons['schedule-orientation'],
        type: 'block',
        color: 'purple'
      },
      {
        key: 'search',
        text: this.$translations.buttons['view-searh'],
        type: 'outline',
        color: 'gray'
      }
    ]

    return {
      config,
      buttons
    }
  },
  methods: {
    hdlClickOptions (key) {
      if (key === 'orientation') {
        this.$router.push({
          name: 'RecommendedCounselling'
        })
      }
      if (key === 'search') {
        this.$router.push({
          name: 'Search'
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.heading-mate
  text-align: center
</style>
