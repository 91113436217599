<template>
  <div class="specialist-pic-card" :style="avatarStyle">
    <YouTubePlayer
      v-if="showMultimediaPlayer"
      :url="specialist.multimedia.url"
      @close="showMultimediaPlayer = false"
    />
    <!-- favorite -->
    <div class="specialist-pic-card-favorite">
      <div
        v-if="favoriteBtn.icon"
        :class="favoriteBtn.icon"
        @click="favoriteBtn.callback"
      />
      <RenderImage :lottie="favoriteBtn.lottie" v-if="favoriteBtn.showLottie" />
    </div>

    <div class="specialist-pic-card-data">
      <!-- full name -->
      <div class="item">
        <div
          class="heading-medium text-white item-ellipsis"
          v-text="specialist.name"
        />
        <img v-if="countryFlag" class="country" :src="countryFlag" alt="" />
      </div>

      <!-- speciality -->
      <div class="item" v-if="specialist.specialist_type">
        <span class="item_icon icon-briefcase-off text-blue bg-blue-20" />
        <span
          class="body-small text-white item-ellipsis"
          v-text="specialist.specialist_type"
        />
      </div>

      <!-- languages -->
      <div class="item" v-if="languages">
        <span class="item_icon icon-language text-green bg-green-20" />
        <span class="body-small text-white item-ellipsis" v-text="languages" />
      </div>
    </div>

    <!-- multimedia -->
    <div
      class="specialist-pic-card-video icon-play"
      @click.stop="openMultimedia"
      v-if="specialist.multimedia"
    />

    <SpecialistsFavoriteDialog
      v-if="showModal"
      :favorite-id="favoriteId"
      :specialist="specialist"
      @close="closeModal($event)"
    />
  </div>
</template>

<script>
import { FLAGS, YouTubePlayer, SpecialistFavorite } from '@seliaco/red-panda'
import RenderImage from '@/components/misc/RenderImage'

import SpecialistsFavoriteDialog from '@/components/dialogs/SpecialistsFavoriteDialog'
import { SpecialistProfileEvent } from '@/types/events'

export default {
  name: 'SpecialistPicCard',
  components: { YouTubePlayer, SpecialistsFavoriteDialog, RenderImage },
  props: {
    specialist: Object
  },
  data () {
    return {
      showModal: false,
      showMultimediaPlayer: false,
      favoriteId: null,
      showLottie: false
    }
  },
  methods: {
    openMultimedia () {
      this.showMultimediaPlayer = true
      this.$segment.track(SpecialistProfileEvent.select_playspecialistvideo)
    },
    addSpecialistFavorite () {
      this.showLottie = true
      SpecialistFavorite.create({ specialist: this.specialist.id })
        .then((res) => {
          this.$toast({
            text: this.$translations['my-specialists']['toast-create'],
            severity: 'success'
          })
          this.favoriteId = res.id
          SpecialistProfileEvent.addFavorite({
            specialistId: this.specialist.id,
            specialistName: this.specialist.name,
            origin: 'Specialista Card Experiment B'
          })

          this.$emit('changed-favorite', this.favoriteId)
        })
        .catch((error) => {
          this.$toast({
            text: error.message,
            severity: 'error'
          })
        })
        .finally(() =>
          setTimeout(() => {
            this.showLottie = false
          }, 2000)
        )
    },
    closeModal (event) {
      if (event) {
        this.favoriteId = null
      }
      this.showModal = false
    }
  },
  computed: {
    favoriteBtn () {
      return {
        callback: () => {
          if (this.favoriteId) {
            this.showModal = true
          } else {
            this.addSpecialistFavorite()
          }
        },
        icon: this.favoriteId ? 'icon-heart-on text-red' : 'icon-heart-off',
        lottie:
          'https://igpedxmysqtwteudvnol.supabase.co/storage/v1/object/public/lottie/lottie-sparkles.json',
        showLottie: this.showLottie
      }
    },
    avatarStyle () {
      return {
        '--avatar': `url('${
          this.specialist.picture || require('@/assets/images/avatar.png')
        }')`
      }
    },
    languages () {
      if (!this.specialist.languages) {
        return null
      }

      const joined = this.specialist.languages
        .map((o) => this.$translations.languages[o])
        .join(', ')

      return `${joined}`
    },
    countryFlag () {
      if (!this.specialist.country_code) {
        return null
      }

      return FLAGS[`${this.specialist.country_code.toLowerCase()}Flag`]
    }
  },
  watch: {
    specialist: {
      handler (value) {
        this.favoriteId = value?.favoriteId
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.specialist-pic-card
  display: grid
  grid-template-columns: 1fr min-content
  align-content: end
  height: 240px
  border-radius: 16px
  padding: 16px
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.13) 65.99%, rgba(0, 0, 0, 0.5) 100%), var(--avatar) center no-repeat
  background-size: cover
  position: relative

  &-video
    align-self: end
    background: var(--purple)
    color: var(--white)
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    padding: 14px

  &-favorite
    background: rgba( 0, 0, 0, .4)
    color: var(--white)
    display: flex
    justify-content: center
    align-items: center
    border-radius: 8px
    padding: 4px
    position: absolute
    top: 8px
    right: 8px
    font-size: 24px
    ::v-deep .lottie
      position: absolute
      width: 300px
      height: 300px
      top: -125px
      left: -134px
      transform: scale(0.5)

  &-data
    display: grid
    gap: 4px
    .item
      display: flex
      align-items: center
      align-self: center
      gap: 4px
      &_icon
        padding: 2px
        border-radius: 4px

    .item-ellipsis
      overflow: hidden
      white-space: nowrap
      white-space: -moz-pre-wrap
      white-space: -pre-wrap
      white-space: -o-pre-wrap
      text-overflow: ellipsis
      max-width: 215px

    .country
      width: 24px
      height: 20px
      border-radius: 2px
      object-fit: cover

@media (min-width: 480px)
  .specialist-pic-card
    height: 340px
</style>
