export default {
  mounted () {
    this.enableNavigationListeners()
  },
  destroyed () {
    this.disableNavigationListeners()
  },
  methods: {
    /**
     * Listeners
     */
    enableNavigationListeners () {
      this.$store.commit('UPDATE_DEVICE_BACK_BUTTON_CALLBACK', this.handleBackButton)
    },
    disableNavigationListeners () {
      this.$store.commit('UPDATE_DEVICE_BACK_BUTTON_CALLBACK', null)
    },
    /**
     * Methods
     */
    handleBackButton () {
      console.warn('handleBackButton: You need to override this method')
    }
  }
}
